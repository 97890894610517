import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "../../atoms/List/Table";
import { getMeetings } from "@syntbeheer/api/services/meetings/index";
import { getCommissioners } from "@syntbeheer/api/services/lots/index";
import { DownloadPdf } from "./../../organisms/PDF";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { Text, View } from "@react-pdf/renderer";
import { getMajorityLabelByValue } from "./../../../definitions/majority";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { withTranslation } from "react-i18next";

class Meetings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Meetings: [],
      Commissioners: [],
    };
  }
  componentDidMount() {
    getMeetings().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ Meetings: response.Meetings });
      }
    });
    getCommissioners().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ Commissioners: response.Commissioners });
      }
    });
  }
  render() {
    const { t } = this.props;
    const { Meetings, Commissioners } = this.state;
    const VME = vmesSelectors.get().VME || {};
    const User = usersSelectors.get().User;
    const formatMeetingItemDescription = (description, Meeting) => {
      return description
        .replace(/[^{{\}}]+(?=})/g, (match) => {
          const path = match.split(".");
          let res = Meeting;
          for (let i = 0; i < path.length; i++)
            res = path[i] in res ? res[path[i]] : null;
          return res;
        })
        .replace(/{{/g, "")
        .replace(/\}}/g, "");
    };
    const columns = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "hidden",
        accessor: "is_cancelled",
      },
      {
        Header: t("dashboard.meetings.columns.name", "Name"),
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.is_cancelled
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {!User.is_admin && User.UserVME?.type !== "synt_authoriser" ? (
                <span>{value}</span>
              ) : (
                <a href={`/app/agenda/${row.values.id}`}>{value}</a>
              )}
            </span>
          );
        },
      },
      {
        Header: t("dashboard.meetings.columns.startsAt", "Start date"),
        accessor: (row) => `${new Date(row.starts_at).toLocaleDateString()}, 
        ${new Date(row.starts_at).toLocaleString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
        id: "start_date",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.is_cancelled
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {value}
            </span>
          );
        },
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "live",
        Cell: ({ row }) => {
          if (!User.is_admin && User.UserVME?.type !== "synt_authoriser") {
            return null;
          }
          const Meeting = this.state.Meetings.find(
            (item) => item.id === row.values.id
          );
          if (Meeting?.type !== "general") {
            return null;
          }
          return (
            <div style={{ textAlign: "right" }}>
              <a
                href={`/app/stream/agenda/${row.values.id}`}
                className="btn p-0 ml-auto"
              >
                {Meeting.is_streaming
                  ? t("Meeting Ongoing", "Meeting Ongoing")
                  : t("Start Meeting", "Start Meeting")}{" "}
                <PlayIcon
                  style={Meeting.is_streaming ? { color: colors.success } : {}}
                />
              </a>
            </div>
          );
        },
      },
      {
        Header: () => null, // No header
        id: "pdf", // It needs an ID
        Cell: ({ cell, row }) => {
          const Meeting = this.state.Meetings.find(
            (item) => item.id === row.values.id
          );
          const isValid = Commissioners.reduce(
            (acc, C) => {
              let U = Meeting.Users.find((U) => C.id === U.id);
              if (U && U.MeetingUser && U.MeetingUser.is_confirmed_at) {
                acc.total_present_shares += C.representing_shares || 0;
                acc.total_present += 1;
              }
              acc.total_shares += C.representing_shares || 0;
              acc.total += 1;
              return acc;
            },
            {
              total_present_shares: 0,
              total_shares: 0,
              total_present: 0,
              total: 0,
            }
          );
          if (Meeting?.type === "general") {
            const document = {
              title: Meeting.name,
              subtitle: `${VME.alias} (${VME.name}), ${VME.address}, ${VME.vat_number}`,
              sections: [
                {
                  title: t(
                    "dashboard.meetings.columns.pdf.document.sections.information.title",
                    "Information"
                  ),
                  text: t(
                    "dashboard.meetings.columns.pdf.document.sections.information.text",
                    "The {{type}} meeting ({{meetingName}}) of the Co-Owners Association, {{VMEAlias}}, will take place on {{startDate}}, {{startTime}} at {{meetingAddress}}. The description of this meeting is as follows: {{meetingDescription}}",
                    {
                      type:
                        Meeting?.type === "general" &&
                        t("dashboard.meetings.meetingType.general", "general"),
                      meetingName: Meeting.name,
                      VMEAlias: VME.alias,
                      startDate: new Date(
                        Meeting.starts_at
                      ).toLocaleDateString(),
                      startTime: new Date(Meeting.starts_at).toLocaleString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      ),
                      meetingAddress: Meeting.address,
                      meetingDescription: Meeting.description,
                    }
                  ),
                },
                {
                  title: "",
                },
                {
                  title: t(
                    "dashboard.meetings.columns.pdf.document.sections.presence.title",
                    "PRESENCE"
                  ),
                  text: Meeting.Users.map((item, index) => {
                    return (
                      <Text key={item.id}>
                        {`> ${index + 1}. ${item.full_name} ${
                          item.MeetingUser.is_confirmed_at
                            ? t(
                                "dashboard.meetings.columns.pdf.document.sections.presence.text.isConfirmed",
                                "(confirmed)"
                              )
                            : t(
                                "dashboard.meetings.columns.pdf.document.sections.presence.text.isNotConfirmed",
                                "(not confirmed)"
                              )
                        }${
                          item.MeetingUser.PowerOfAttorneyGivenToUser
                            ? `${"\n"}` +
                              t(
                                "dashboard.meetings.columns.pdf.document.sections.presence.text.representedBy",
                                " represented by: "
                              ) +
                              item.MeetingUser.PowerOfAttorneyGivenToUser
                                .full_name +
                              t(
                                "dashboard.meetings.columns.pdf.document.sections.presence.text.coResident",
                                " (co-resident)"
                              )
                            : item.MeetingUser
                                .power_of_attorney_given_to_first_name
                            ? `${"\n"}` +
                              t(
                                "dashboard.meetings.columns.pdf.document.sections.presence.text.representedBy",
                                " represented by: "
                              ) +
                              item.MeetingUser
                                .power_of_attorney_given_to_first_name +
                              " " +
                              item.MeetingUser
                                .power_of_attorney_given_to_last_name +
                              t(
                                "dashboard.meetings.columns.pdf.document.sections.presence.text.external",
                                " (external)"
                              )
                            : ""
                        } (${
                          new Date(
                            item.MeetingUser.createdAt
                          ).toLocaleDateString() +
                          ", " +
                          new Date(item.MeetingUser.createdAt).toLocaleString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        })`}
                        {"\n"}
                        {"\n"}
                      </Text>
                    );
                  }),
                },
                {
                  title: t(
                    "dashboard.meetings.columns.pdf.document.sections.calendarItems.title",
                    "CALENDAR ITEMS"
                  ),
                },
                ...Meeting.MeetingItems.map((item, index) => {
                  return {
                    title: `> ${index + 1}. ${item.name}`,
                    text: (
                      <View>
                        {!!item?.description && (
                          <>
                            <Text style={{ marginBottom: 15 }}>
                              {formatMeetingItemDescription(
                                item.description,
                                Meeting
                              )}
                            </Text>

                            <Text>{"\n"}</Text>
                            <Text>{"\n"}</Text>
                          </>
                        )}
                        {item.majority && item.majority !== "" && (
                          <>
                            <Text>
                              {t(
                                "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.requiredForApproval",
                                "Required for approval: "
                              )}
                              {getMajorityLabelByValue(t, item.majority)}
                            </Text>
                            <Text>{"\n"}</Text>
                            <Text>
                              {t(
                                "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.vote",
                                "Vote: "
                              )}
                              {"\n"}
                            </Text>
                            {item.Users.map((user) => (
                              <Text key={user.id}>
                                - {user.full_name}:{" "}
                                {user.MeetingItemUser.is_pro === true
                                  ? t(
                                      "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.for",
                                      "For"
                                    )
                                  : user.MeetingItemUser.is_pro === false
                                  ? t(
                                      "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.against",
                                      "Against"
                                    )
                                  : t(
                                      "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.withhold",
                                      "Withhold"
                                    )}{" "}
                                (
                                {Commissioners.reduce((acc, C) => {
                                  if (user.id === C.id) {
                                    return (acc = C.representing_shares);
                                  }
                                  return acc;
                                }, 0)}
                                ){"\n"}
                              </Text>
                            ))}
                            <Text>
                              {t(
                                "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.result",
                                "Result: "
                              )}{" "}
                              {item.passed
                                ? t(
                                    "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.approved",
                                    "Approved"
                                  )
                                : t(
                                    "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.disapproved",
                                    "Disapproved"
                                  )}{" "}
                              ({item.total_pro}/{item.total_shares})
                            </Text>
                            <Text>{"\n"}</Text>
                          </>
                        )}
                        {item.decision && (
                          <Text>
                            {t(
                              "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.decision",
                              "Decision: "
                            )}
                            {item.decision}
                          </Text>
                        )}
                      </View>
                    ),
                  };
                }),
              ],
            };
            return (
              <div className="d-flex justify-content-end">
                <DownloadPdf document={document} index={Meeting.id} />
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];
    return (
      <div className="row">
        <div className="col">
          <Table
            columns={columns}
            data={Meetings}
            title={t("dashboard.meetings.table.title", "Events")}
            description=""
            enableSearch={false}
            enableSort={false}
            addPath={
              !User.is_admin && User.UserVME?.type !== "synt_authoriser"
                ? null
                : "/app/agenda"
            }
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Meetings);
