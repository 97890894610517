import React from "react";

import logo_red from "@syntbeheer/assets/brand/logo_red.svg";
import PersonIcon from "@material-ui/icons/AccountCircleRounded";
import CompanyIcon from "@material-ui/icons/Business";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "../atoms/Buttons/Button";
import { revoke } from "@syntbeheer/api/services/users/index";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { selectVME } from "@syntbeheer/api/services/users/index";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { useTranslation } from "react-i18next";
import { VMEsPicker } from "../atoms/Input/VMEsPicker";

export default function DashboardHeader(props) {
  let User = usersSelectors.get().User;
  const [toggle, setToggle] = React.useState(false);
  const VME = vmesSelectors.get().VME || {};
  const { t } = useTranslation("common");

  function handleLogout(event) {
    event.preventDefault();
    revoke().then((response) => {
      if (response.success) {
        window.location.href = "/";
      }
    });
  }
  function onToggle(event) {
    console.log(event);
    setToggle(!toggle);
    if (toggle) {
      document.getElementById("dashboard-menu").style.display = "none";
    } else {
      document.getElementById("dashboard-menu").style.display = "block";
    }
  }
  return (
    <Navbar bg="light" expand="lg" style={{ zIndex: 1 }}>
      <Navbar.Brand>
        <a href="/app/#Overview">
          <img
            src={logo_red}
            alt="Synt Logo"
            style={{ maxWidth: 20, verticalAlign: "text-top", marginRight: 6 }}
          />
        </a>
        <span className="m-none">
          {t("dashboard.header.brand", "Synt Dashboard")} | {props.title}
        </span>
      </Navbar.Brand>
      <Navbar.Toggle onClick={onToggle} />
      <Navbar.Collapse id="dashboard-header">
        <Nav className="mr-auto"></Nav>
        <Nav className="navbar-nav mr-5">
          <VMEsPicker
            value={{
              label: VME.alias,
              value: VME.id,
            }}
            onChange={(selected) => {
              // change VME
              selectVME(selected.value)
                .then((response) => {
                  console.log(response);
                  if (response.success) {
                    window.location.reload();
                  }
                })
                .catch((e) => console.log(e));
            }}
            marginbottom={0}
          />
        </Nav>
        <Nav className="navbar-nav" id="dashboard-profile-menu">
          <a href="/app#Profile" className="my-3 mx-2">
            {User.first_name}{" "}
            {User.Company ? (
              <>
                <CompanyIcon style={{ verticalAlign: "text-top" }} />{" "}
                {User.Company.vat_number}
              </>
            ) : (
              <PersonIcon style={{ verticalAlign: "text-top" }} />
            )}
          </a>
          <Form inline onSubmit={handleLogout}>
            <Button
              buttontype="btn-sm"
              title={t("dashboard.header.logout", "Log out")}
            />
          </Form>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
