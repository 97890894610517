import React from "react";
// import AutoNumeric from "autonumeric";

export default class Field extends React.Component {
  static defaultProps = {
    marginbottom: 1,
    decimals: 2,
  };
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      numeric: null,
      focus: false,
    };
  }

  handleInputFocus = () => {
    this.setState({ focus: true });
  };

  handleInputBlur = () => {
    this.setState({ focus: false });
  };

  formatNumber = (num) => {
    if (typeof num === "undefined") num = "";
    return parseFloat(num.toString().replace(",", ".") || 0)
      .toFixed(this.props.decimals) // always two decimal digits
      .replace(".", ",") // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };
  componentDidMount() {
    // FIXME: difficult to keep in sync with state variable, they have to be numbers not texts
    /*
    if (this.props.type === "number") {
      const numeric = new AutoNumeric(this.input.current, {
        decimalCharacter: ",",
        decimalPlaces: 2,
        digitGroupSeparator: ".",
        modifyValueOnWheel: false,
        unformatOnSubmit: true, // not working
      });
      this.setState({ numeric });
    }
    */
  }
  render() {
    const { props } = this;
    return (
      <div
        className={`form-group has-validation ${
          props.marginbottom ? "mb-5" : ""
        }`}
      >
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <div className="input-group">
          {props.prepend && (
            <div className="input-group-prepend">
              <span className="input-group-text">{props.prepend}</span>
            </div>
          )}
          <input
            {...props}
            type={props.type === "number" ? "text" : props.type}
            value={
              props.type === "number"
                ? this.state.focus
                  ? props.value
                  : props.value === 0 || props.value === ""
                  ? ""
                  : this.formatNumber(props.value)
                : props.value
            }
            aria-describedby={`${props.name}Help`}
            id={props.name}
            className={`form-control ${props.error ? "is-invalid" : ""}`}
            ref={this.input}
            onChange={(event) => {
              if (props.keepevent) {
                props.onChange(event);
                return;
              }
              let value = event.target.value;
              if (props.type === "number") {
                //value = this.state.numeric.rawValue;
                value = value.replace(",", ".");
                this.formatNumber(value);
              }
              props.onChange(value);
            }}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
          />
          {props.append && (
            <div
              className={
                "input-group-append" +
                (props.onClickAppend ? " cursorPointer" : "")
              }
              onClick={props.onClickAppend}
            >
              <span className="input-group-text">{props.append}</span>
            </div>
          )}
          {props.error && (
            <small
              id={`${props.name}Help`}
              className="invalid-feedback form-text"
            >
              {props.error}
            </small>
          )}
        </div>
        {props.help && (
          <small id={`${props.name}Help`} className="form-text text-muted">
            {props.help}
          </small>
        )}
      </div>
    );
  }
}
