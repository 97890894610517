import React from "react";
import styled from "styled-components";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useGroupBy,
  useSortBy,
  useExpanded,
} from "react-table";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import colors from "@syntbeheer/assets/colors";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import MoveIcon from "@material-ui/icons/OpenWith";
import { useTranslation } from "react-i18next";
import CheckBox from "../Input/CheckBox";
import EmailIcon from "@material-ui/icons/EmailRounded";

const Styles = styled.div`
  @media only screen and (max-width: 768px) {
  }

  @media only screen and (min-width: 768px) {
    padding: 1rem;
  }

  /* This is required to make the table full-width */
  display: block;
  padding-top: 6px;
  max-width: 100%;
  max-height: 500px;
  overflow-y: auto;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
          :first-child {
            border-bottom-left-radius: 10px;
          }
          :last-child {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid white;
      border-right: 1px solid white;

      :last-child {
        border-right: 0;
      }
    }

    tbody {
    }

    tbody tr:nth-child(even) {
      background: #f8f9fa;
    }
    tbody tr:nth-child(odd) {
      background: #dcdcdc;
    }
  }
`;

function ExportButton({ onClick, title }) {
  const { t } = useTranslation("common");
  return (
    <button
      className="btn btn-outline-primary mx-1"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      ⇑ {title ?? t("customTable.exportButton", "Export")}
    </button>
  );
}

function EmailButton({ onClick, title }) {
  const { t } = useTranslation("common");
  return (
    <button
      className="btn btn-outline-primary mx-1"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <EmailIcon /> {title ?? t("customTable.emailButton", "Send")}
    </button>
  );
}

function AddButton({ path, title }) {
  const { t } = useTranslation("common");
  return (
    <a href={path} className="btn btn-outline-primary mx-1">
      + {title ?? t("customTable.addButton", "New")}
    </a>
  );
}

// Define a default UI for filtering
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const { t } = useTranslation("common");

  return (
    <form className="form-inline">
      <SearchIcon /> {t("customTable.globalFilter.title", "Filter")}:{" "}
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={t(
          "customTable.globalFilter.searchPlaceholder",
          "search..."
        )}
        style={{
          fontSize: "1.1rem",
          border: "0",
          boxShadow: "none",
        }}
      />
    </form>
  );
}

function CustomTable({
  columns,
  data,
  title = null,
  description = null,
  enableSearch = false,
  enableSort = false,
  addPath = false,
  addTitle,
  add = false,
  renderRowSubComponent = false,
  groupedByAccessors = [],
  draggable = false,
  onExport,
  onEmail = false,
  enableExport = false,
  enableReminder = false,
  exportTitle,
  enableColumnSelections = false,
  selectedColumns,
  onSelectColumn,
  renderCustomSearch,
}) {
  const [records, setRecords] = React.useState(data);
  const { t } = useTranslation("common");

  // const getRowId = React.useCallback((row) => {
  //   //FIXME: not working with tables without proper id
  //   return row.id > 0 && typeof row.id !== "undefined" ? row.id : 0;
  // }, []);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setGroupBy,
    state: { groupBy },
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["id", "is_cancelled", "solved_at"],
        groupBy: [groupedByAccessors[0]],
      },
      autoResetExpanded: false,
      stateReducer: (newState, action, prevState) => {
        if (
          [
            "resetHiddenColumns",
            "resetGlobalFilter",
            "resetGroupBy",
            "resetSortBy",
          ].includes(action.type)
        ) {
          return prevState;
        }
        return newState;
      },
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded
  );

  React.useEffect(() => {
    toggleAllRowsExpanded(true);
  }, []);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  const getLeafColumns = function (rootColumns) {
    return rootColumns.reduce((leafColumns, column) => {
      if (column.columns) {
        return [...leafColumns, ...getLeafColumns(column.columns)];
      } else {
        return [...leafColumns, column];
      }
    }, []);
  };

  const renderColumnSelectController = (column) => {
    if (!(enableColumnSelections && onSelectColumn && selectedColumns)) {
      return;
    }

    return (
      <CheckBox
        label=""
        checked={selectedColumns[column.id]}
        onChange={(event) => {
          const { checked } = event.target;
          onSelectColumn(column.id, checked);
        }}
        marginbottom={0}
      />
    );
  };

  // Render the UI for your table)
  return (
    <DndProvider backend={HTML5Backend}>
      <table {...getTableProps()}>
        <thead>
          {(title ||
            enableSearch ||
            addPath ||
            description ||
            enableExport) && (
            <tr>
              <th colSpan={visibleColumns.length}>
                {(title ||
                  enableSearch ||
                  addPath ||
                  enableExport ||
                  renderCustomSearch) && (
                  <div className="row">
                    {title && (
                      <div className="col my-auto">
                        <h3
                          className={`mb-0 ${
                            !enableSearch && !addPath ? "mt-3" : ""
                          }`}
                          style={{ fontWeight: 700, color: colors.primary }}
                        >
                          {title}
                        </h3>
                      </div>
                    )}
                    {renderCustomSearch && (
                      <div className="col my-auto">{renderCustomSearch()}</div>
                    )}
                    {enableSearch && (
                      <div className="col my-auto">
                        <GlobalFilter
                          preGlobalFilteredRows={preGlobalFilteredRows}
                          globalFilter={state.globalFilter}
                          setGlobalFilter={setGlobalFilter}
                        />
                      </div>
                    )}
                    {(addPath || (enableExport && onExport)) && (
                      <div className="col my-auto d-flex justify-content-end one-line">
                        {enableReminder && onEmail && (
                          <EmailButton onClick={() => onEmail(rows)} />
                        )}
                        {enableExport && onExport && (
                          <ExportButton
                            path={addPath}
                            title={exportTitle}
                            onClick={() => onExport(rows)}
                          />
                        )}
                        {addPath && (
                          <AddButton path={addPath} title={addTitle} />
                        )}
                      </div>
                    )}
                    {add && (
                      <div className="col my-auto d-flex justify-content-end align-items-center">
                        {add}
                      </div>
                    )}
                  </div>
                )}
                {description && (
                  <div className="row">
                    <div className="col my-auto">
                      <small>{description}</small>
                    </div>
                  </div>
                )}
              </th>
            </tr>
          )}
          {groupedByAccessors.length > 0 && (
            <tr>
              <th colSpan={visibleColumns.length}>
                <div className="row">
                  <div className="col">
                    ⭕ {t("customTable.group.title", "Group")}:{" "}
                    <select
                      value={groupBy[0]}
                      onChange={(e) => {
                        setGroupBy([e.target.value]);
                      }}
                    >
                      <option value="">
                        {t("customTable.group.noneOption", "None")}
                      </option>
                      {getLeafColumns(columns).map((column) => {
                        if (groupedByAccessors.includes(column.accessor)) {
                          return (
                            <option
                              key={column.accessor}
                              value={column.accessor}
                            >
                              {column.Header}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </select>
                  </div>
                </div>
              </th>
            </tr>
          )}
          {headerGroups.map((headerGroup) => {
            const { key: key1, ...props1 } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key1} {...props1}>
                {headerGroup.headers.map((column) => {
                  const { key, ...props } = column.getHeaderProps();
                  const { key: keyS, ...propsS } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return enableSort ? (
                    <th
                      key={keyS}
                      {...propsS}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sort-desc"
                            : "sort-asc"
                          : ""
                      }
                    >
                      {renderColumnSelectController(column)}
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? "⭕ " : ""}
                        </span>
                      ) : null}
                      {column.render("Header")}
                      {column.Header.length > 0 && (
                        <span style={{ marginRight: 12 }}>
                          <SortIcon
                            style={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? { opacity: 1, transform: "scaleY(-1)" }
                                  : { opacity: 1 }
                                : { opacity: 0.2 }
                            }
                          />
                        </span>
                      )}
                    </th>
                  ) : (
                    <th key={key} {...props}>
                      {renderColumnSelectController(column)}
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && (
            <tr>
              <td colSpan={visibleColumns.length}>
                <div>
                  <p>
                    {t(
                      "customTable.noElements",
                      "There are currently no elements."
                    )}{" "}
                  </p>
                </div>
              </td>
            </tr>
          )}
          {rows.map((row, i) => {
            prepareRow(row);
            const { key: keyRows, propsRow } = row.getRowProps();
            return (
              <Row
                index={i}
                row={row}
                moveRow={moveRow}
                visibleColumns={visibleColumns}
                renderRowSubComponent={renderRowSubComponent}
                draggable={draggable}
                key={keyRows}
                {...propsRow}
              />
            );
          })}
        </tbody>
      </table>
    </DndProvider>
  );
}

const Row = ({
  draggable,
  row,
  index,
  moveRow,
  renderRowSubComponent,
  visibleColumns,
}) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: "row",
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "row",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  const { key: topRowKey, ...topRowProps } = row.getRowProps();
  return (
    <React.Fragment key={row.getRowProps().key}>
      <tr key={topRowKey} {...topRowProps} ref={dropRef} style={{ opacity }}>
        {row.cells.map((cell, index) => {
          const { key: keyCell, propsCell } = cell.getCellProps();
          return (
            <td key={keyCell} {...propsCell}>
              {cell.isGrouped ? (
                // If it's a grouped cell, add an expander and row count
                <>
                  <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? "⬇️" : "➡️"}
                  </span>{" "}
                  {cell.render("Cell")} ({row.subRows.length})
                </>
              ) : cell.isAggregated ? (
                // If the cell is aggregated, use the Aggregated
                // renderer for cell
                cell.render("Aggregated")
              ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                // Otherwise, just render the regular cell
                cell.render("Cell")
              )}
              {row.cells.length === index + 1 && draggable && (
                <>
                  <span style={{ float: "right" }} ref={dragRef}>
                    <MoveIcon />
                  </span>
                </>
              )}
            </td>
          );
        })}
      </tr>
      {row.isExpanded && renderRowSubComponent ? (
        <tr>
          <td colSpan={visibleColumns.length}>
            {/*
                      Inside it, call our renderRowSubComponent function. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
            {renderRowSubComponent({ row })}
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  );
};

export default function Table(props) {
  const headers = props.columns; // useMemo to update columns
  return (
    <Styles>
      <CustomTable
        columns={headers}
        data={props.data}
        title={props.title}
        description={props.description}
        enableSearch={props.enableSearch}
        enableSort={props.enableSort}
        addPath={props.addPath}
        addTitle={props.addTitle}
        add={props.add}
        draggable={props.draggable}
        groupedByAccessors={props.groupedByAccessors}
        renderRowSubComponent={props.renderRowSubComponent}
        onExport={props.onExport}
        onEmail={props.onEmail}
        enableExport={props.enableExport}
        enableReminder={props.enableReminder}
        exportTitle={props.exportTile}
        enableColumnSelections={props.enableColumnSelections}
        selectedColumns={props.selectedColumns}
        onSelectColumn={props.onSelectColumn}
        renderCustomSearch={props.renderCustomSearch}
      />
    </Styles>
  );
}
