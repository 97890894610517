import React from "react";

import AutorenewIcon from "@material-ui/icons/Autorenew";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
`;

function LoadingRotating({ loading }) {
  return (
    <Rotate style={{ visibility: loading ? "visible" : "hidden" }}>
      <AutorenewIcon />
    </Rotate>
  );
}

export default LoadingRotating;
