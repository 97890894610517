import React from "react";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ApartmentIcon from "@material-ui/icons/Apartment";
import DefinitionsIcon from "@material-ui/icons/Spellcheck";
import IncidentIcon from "@material-ui/icons/Opacity";
import FinancialIcon from "@material-ui/icons/AccountBalance";
import AccountingIcon from "@material-ui/icons/PieChart";
import DocumentsIcon from "@material-ui/icons/Description";
import AgendaIcon from "@material-ui/icons/ViewAgenda";
import SuppliersIcon from "@material-ui/icons/BusinessCenter";
import SalesIcon from "@material-ui/icons/MoneyRounded";
import { withTranslation } from "react-i18next";
import ToggleIcon from "@material-ui/icons/SyncAlt";
import ReminderIcon from "@material-ui/icons/NotificationsActive";
import BookkeepingIcon from "@material-ui/icons/AccountBalanceWallet";

import Nav from "react-bootstrap/Nav";
import NavItem from "../atoms/List/NavItem";
import {withRouter} from 'react-router';

import * as usersSelectors from "@syntbeheer/api/services/users/selectors";

class DashboardMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: props.activeKey,
      minimal: false,
      showAccounting: null,
    };
  }
  handleSelect = (selectedKey) => {
    this.setState({ activeKey: selectedKey }, () => {
      // set active parent nav item
      // done by sending state to NavItems
      /* let items = document.getElementsByClassName("nav-item active");
      [].forEach.call(items, function (item) {
        item.classList.remove("active");
      });

      let links = document.getElementsByClassName("nav-link active");
      [].forEach.call(links, function (link) {
        link.parentElement.classList.add("active");
      }); */
    });
  };
  isAccountingGroup = () => {
    return [
      '#Purchases',
      '#Financial',
      '#Provisions',
      '#Accounting',
      '#Bookkeeping'
    ].includes(this.props.location.hash) || [
      '/app/invoice',
      '/app/provision',
      '/app/financial-year'
    ].some(pathname => this.props.location.pathname.startsWith(pathname));
  };

  shouldShowAccountingNavs = () => {
    const accountingGroup = this.isAccountingGroup();
    if (accountingGroup) {
      if (this.state.showAccounting === null) {
        return true;
      }
      return this.state.showAccounting;
    }

    return Boolean(this.state.showAccounting);
  };

  render() {
    const { t } = this.props;
    const showAccounting = this.shouldShowAccountingNavs();
    const User = usersSelectors.get().User;

    return (
      <Nav
        className={`bg-light ${
          this.state.minimal ? "" : "col-md-2"
        } p-0 dashboard-menu`}
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
        id="dashboard-menu"
      >
        <button
          type="button"
          className="btn"
          onClick={() => this.setState({ minimal: !this.state.minimal })}
          style={{
            position: "absolute",
            bottom: 10,
            boxShadow: "none",
          }}
        >
          <div style={{ marginTop: 30 }}>
            <ToggleIcon />
          </div>
        </button>
        {/* <div className="m-none">
          <NavItem
            icon={
              <img
                src={logo_red}
                alt="Synt Logo"
                style={{
                  maxWidth: 20,
                  verticalAlign: "text-top",
                  marginLeft: 2,
                }}
              />
            }
            href="/app#"
          />
        </div> */}
        <NavItem
          icon={<DashboardIcon />}
          title={
            this.state.minimal
              ? ""
              : t("dashboard.menu.overview.title", "Overview")
          }
          href="/app#Overview"
          {...this.state}
        />
        {User.is_admin && (
          <>
            <NavItem
              icon={<ApartmentIcon />}
              title={
                this.state.minimal
                  ? ""
                  : t("dashboard.menu.associations.title", "VMEs") + " (A)"
              }
              href="/app#Associations"
              {...this.state}
            />
            <NavItem
              icon={<SalesIcon />}
              title={
                this.state.minimal
                  ? ""
                  : t("dashboard.menu.sales.title", "Sales") + " (A)"
              }
              href="/app#Sales"
              {...this.state}
            />
            <NavItem
              icon={<DefinitionsIcon />}
              title={
                this.state.minimal
                  ? ""
                  : t("dashboard.menu.definitions.title", "Definitions") +
                    " (A)"
              }
              href="/app#Definitions"
              {...this.state}
            />
          </>
        )}
        <NavItem
          icon={<IncidentIcon />}
          title={
            this.state.minimal
              ? ""
              : t("dashboard.menu.incidents.title", "Notifications")
          }
          href="/app#Incidents"
          {...this.state}
        />
        {(User.is_admin || !(User.UserVME?.type === "regular_user")) && (
          <>
            <NavItem
              icon={<FinancialIcon />}
              title={
                this.state.minimal
                  ? ""
                  : t("dashboard.menu.accounting.title", "Accounting")
              }
              onClick={() => {
                this.setState((prevState) => ({
                  showAccounting: !showAccounting
                }));
              }}
              dropdown
              expanded={showAccounting}
              {...this.state}
            />
            {showAccounting && (
              <>
                <NavItem
                  icon={<FinancialIcon />}
                  title={
                    this.state.minimal
                      ? ""
                      : t("dashboard.menu.financial.title", "Bank Accounts")
                  }
                  dropdownItem
                  href="/app#Financial"
                  {...this.state}
                />
                <NavItem
                  icon={<FinancialIcon />}
                  title={
                    this.state.minimal
                      ? ""
                      : t("dashboard.menu.purchases.title", "Purchases")
                  }
                  dropdownItem
                  href="/app#Purchases"
                  {...this.state}
                />
                <NavItem
                  icon={<FinancialIcon />}
                  title={
                    this.state.minimal
                      ? ""
                      : t("dashboard.menu.provisions.title", "Provisions")
                  }
                  dropdownItem
                  href="/app#Provisions"
                  {...this.state}
                />
                <NavItem
                  icon={<AccountingIcon />}
                  title={
                    this.state.minimal
                      ? ""
                      : t(
                          "dashboard.menu.financial_years.title",
                          "Financial Years"
                        )
                  }
                  dropdownItem
                  href="/app#Accounting"
                  {...this.state}
                />
                <NavItem
                  icon={<BookkeepingIcon />}
                  title={
                    this.state.minimal
                      ? ""
                      : t("dashboard.menu.bookkeeping.title", "Reports")
                  }
                  dropdownItem
                  href="/app#Bookkeeping"
                  {...this.state}
                />
              </>
            )}
            <NavItem
              icon={<DocumentsIcon />}
              title={
                this.state.minimal
                  ? ""
                  : t("dashboard.menu.documents.title", "Documents")
              }
              href="/app#Documents"
              {...this.state}
            />
          </>
        )}
        <NavItem
          icon={<AgendaIcon />}
          title={
            this.state.minimal
              ? ""
              : t("dashboard.menu.meetings.title", "Agenda")
          }
          href="/app#Meetings"
          {...this.state}
        />
        <NavItem
          icon={<SuppliersIcon />}
          title={
            this.state.minimal
              ? ""
              : t("dashboard.menu.suppliers.title", "Suppliers")
          }
          href="/app#Suppliers"
          {...this.state}
        />
        <NavItem
          icon={<ApartmentIcon />}
          title={
            this.state.minimal
              ? ""
              : t("dashboard.menu.data.title", "Basic data")
          }
          href="/app#Data"
          {...this.state}
        />
        <NavItem
          icon={<ReminderIcon />}
          title={
            this.state.minimal
              ? ""
              : t("dashboard.menu.reminders.title", "Reminders")
          }
          href="/app#Reminders"
          {...this.state}
        />
        <NavItem title="" {...this.state} />
        <div style={{ marginBottom: 50 }} />
      </Nav>
    );
  }
}

export default withRouter(withTranslation("common")(DashboardMenu));
