import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "./../../atoms/List/Table";
import { getDocuments } from "@syntbeheer/api/services/documents/index";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import FolderIcon from "@material-ui/icons/FolderRounded";
import CreateFolderIcon from "@material-ui/icons/CreateNewFolderRounded";
import PaymentButton from "../../organisms/banking/PaymentButton";
import {
  PaymentStatus,
  translatePaymentStatus,
} from "../../organisms/banking/PaymentStatus";
import { useTranslation } from "react-i18next";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";

function Documents() {
  const [Documents, setDocuments] = React.useState([]);
  const User = usersSelectors.get().User;
  const hasAccessToCreate =
    User?.UserVME?.type !== "synt_viewer" &&
    User?.UserVME?.type !== "commissioner";

  React.useEffect(() => {
    getDocuments().then((response) => {
      console.log(response);
      if (response.success) {
        setDocuments(
          [
            ...response.Documents,
            ...response.PurchaseFiles.map((p) => ({
              ...p,
              name:
                p.name +
                " " +
                (p?.Purchase?.Supplier?.alias ||
                  p?.Purchase?.Supplier?.Company?.name ||
                  ""),
            })),
            ...response.SettlementFiles,
            ...response.ProvisionFiles,
          ].map((D) => ({ ...D, category: D.DocumentType?.name }))
        );
      }
    });
  }, []);

  const { t } = useTranslation("common");

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.documents.columns.name", "Name"),
      accessor: "name",
      Cell: ({ row, value }) => {
        return row.original ? (
          <a
            href={
              row.original.presignedUrl
                ? row.original.presignedUrl
                : `/app/document/${row.values.id}`
            }
            target={row.original.presignedUrl ? "_blank" : ""}
            rel="noreferrer"
          >
            {row.original.presignedUrl ? <PDFIcon /> : <FolderIcon />} {value}
          </a>
        ) : (
          ""
        );
      },
    },
    {
      Header: t("dashboard.documents.columns.category", "Category"),
      accessor: "category",
    },
    {
      Header: t("dashboard.documents.columns.visibility", "Visibility"),
      accessor: "visibility_translation",
      Cell: ({ row, value }) => (
        <span>
          {value}
          {`${
            row?.original?.visibility
              ? " (" + row?.original?.visibility + ")"
              : ""
          }`}
        </span>
      ),
    },
    {
      Header: "",
      id: "buttons",
      Cell: ({ row, value }) =>
        row.original?.Provision ? (
          <div className="d-flex justify-content-between align-items-center">
            {row.original.Provision.paid_at ? (
              <span>{translatePaymentStatus(t, "paid")}</span>
            ) : (
              <>
                <PaymentButton
                  id={row.original.Provision.id}
                  type="Provision"
                />
                <PaymentStatus
                  id={row.original.Provision.id}
                  paid_at={row.original.Provision.paid_at}
                  payment={row.original.Provision.payment}
                  type="Provision"
                />
              </>
            )}
          </div>
        ) : null,
    },
  ];
  return (
    <div className="row">
      <div className="col">
        <h2 style={{ fontWeight: 700, color: colors.primary }}>
          {t("dashboard.documents.title", "Documents")}
        </h2>

        <Table
          columns={columns}
          data={Documents.filter(
            (D) => !D.DocumentType || D.DocumentType.category !== "startup"
          )}
          title={t("dashboard.documents.overviewTable.title", "Overview")}
          description={t(
            "dashboard.documents.overviewTable.description",
            "Documents categorized by type and visibility."
          )}
          enableSearch={true}
          enableSort={true}
          groupedByAccessors={["category"]}
          addPath={hasAccessToCreate ? "/app/document" : null}
          addTitle={
            hasAccessToCreate ? (
              <>
                {t("dashboard.documents.overfiewTable.addButton", "Folder")}{" "}
                <CreateFolderIcon />
              </>
            ) : null
          }
        />

        <Table
          columns={columns}
          data={Documents.filter(
            (D) => !D.DocumentType || D.DocumentType.category === "startup"
          )}
          title={t("dashboard.documents.startupTable.title", "Startup")}
          description={t(
            "dashboard.documents.startupTable.description",
            "All documents needed to activate your VME. These are public for the entire VME."
          )}
          enableSearch={false}
          enableSort={false}
        />
      </div>
    </div>
  );
}

export default Documents;
