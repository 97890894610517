import React from "react";
import Table from "../../atoms/List/Table";
import { getTransactions } from "@syntbeheer/api/services/banking/index";
import { useTranslation } from "react-i18next";
import { decodeStructuredCommunication } from "../../../helpers/utils";

const BankTransactions = React.forwardRef((props, ref) => {
  const [accounts, setAccounts] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);
  const [redirect, setRedirect] = React.useState(null);

  const { t } = useTranslation("common");

  React.useImperativeHandle(ref, () => ({
    updateBanking() {
      updateBanking();
    },
  }));

  const updateBanking = React.useCallback(() => {
    setAccounts([]);
    setErrorText(false);

    getTransactions().then((response) => {
      console.log(response);
      if (response.success) {
        // banking successful
        setAccounts(response.Accounts);
      }
      if (response.redirect) {
        // redirect: auth or payment
        setRedirect(response.redirect);
        setShowModal(true);
        //window.location.href = response.redirect;
      } else if (response.error) {
        setErrorText(
          t(
            "dashboard.banking.transactions.bankLinkError",
            "Bank link error: "
          ) + JSON.stringify(response.error)
        );
      }
    });
  }, [t]);

  React.useEffect(() => {
    //INFO: popup / auto fetching
    updateBanking();
  }, [updateBanking]);

  const columnsTransactions = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t(
        "dashboard.banking.transactions.columns.counterpartName",
        "Beneficiary"
      ),
      accessor: "counterpartName",
    },
    {
      Header: t(
        "dashboard.banking.transactions.columns.executionDate",
        "Execution date"
      ),
      accessor: "executionDate",
      Cell: ({ row, value }) => {
        return new Date(value).toLocaleDateString();
      },
    },
    {
      Header: t("dashboard.banking.transactions.columns.amount", "Amount [€]"),
      accessor: "amount",
      Cell: ({ row, value }) => {
        return value
          .toFixed(2) // always two decimal digits
          .replace(".", ",");
      },
    },
    {
      Header: t(
        "dashboard.banking.transactions.columns.remittanceInformation",
        "Structured Reference"
      ),
      accessor: "remittanceInformation",
      Cell: ({ row, value }) => {
        const is_structured =
          row.original.remittanceInformationType === "structured" &&
          row.values.amount > 0;
        let info = { isValid: false, is_structured };
        if (is_structured) info = decodeStructuredCommunication(value);
        console.log(info);
        return info.isValid ? (
          <>
            {info.id} {info.type} {value}
          </>
        ) : (
          value
        );
      },
    },
  ];

  return (
    <>
      {errorText && (
        <div className="alert alert-danger mt-5" role="alert">
          {errorText}
        </div>
      )}
      {accounts.map((account, index) => (
        <Table
          columns={columnsTransactions}
          data={account.transactions}
          title={
            t("dashboard.banking.transactions.table.title", "Transactions") +
            " " +
            account.reference
          }
          enableSearch={true}
          enableSort={true}
          key={"account" + index}
        />
      ))}
    </>
  );
});

export default BankTransactions;
