import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "./../../atoms/List/Table";
import { getAccountingOverview } from "@syntbeheer/api/services/accounting/index";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { useTranslation, Trans } from "react-i18next";

export default function Accounting() {
  const [financialYears, setFinancialYears] = React.useState([]);
  const [showAccounting, setShowAccounting] = React.useState(false);
  const VME = vmesSelectors.get().VME || {};
  const { t } = useTranslation("common");

  React.useEffect(() => {
    getAccountingOverview()
      .then((response) => {
        setFinancialYears(
          response.FinancialYears.sort((a, b) => (a.year > b.year ? -1 : 1))
        );
        if (response.GLAccounts?.length) {
          setShowAccounting(true);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h2 style={{ fontWeight: 700, color: colors.primary }}>
              {t("dashboard.accounting.title", "Accountancy")}
            </h2>
          </div>
        </div>

        {showAccounting ? (
          <>
            <YearsTable
              data={financialYears}
              addPath={
                VME.financial_year_starts_at ? "/app/financial-year" : null
              }
              description={
                !VME.financial_year_starts_at &&
                t(
                  "dashboard.accounting.years.description",
                  "The start of the financial year must first be known."
                )
              }
            />
          </>
        ) : (
          <div>
            <Trans
              i18nKey="dashboard.accounting.hideAccounting.description"
              t={t}
              ns="common"
            >
              First of all, the general ledger accounts of the VME must be
              known. Go to{" "}
              <a href="/app/general-ledger-accounts">G/L Accounts</a>.
            </Trans>
          </div>
        )}
      </div>
    </div>
  );
}

function YearsTable({ data, addPath, description }) {
  const { t } = useTranslation("common");
  const User = usersSelectors.get().User;
  const hasAccessToCreate =
    User?.UserVME?.type !== "synt_viewer" &&
    User?.UserVME?.type !== "commissioner";

  const columnsYears = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.accounting.years.columns.year", "Year"),
      accessor: "year",
      Cell: ({ row, value }) => {
        return hasAccessToCreate ? (
          <a href={`/app/financial-year/${row.values.id}`}>{value}</a>
        ) : (
          value
        );
      },
    },
    {
      Header: t("dashboard.accounting.years.columns.budget", "Budget"),
      accessor: (row) =>
        row.budget
          ? "€" +
            row.budget
              .toFixed(2) // always two decimal digits
              .replace(".", ",") // replace decimal point character with ,
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
          : "N/A",
    },
    {
      Header: t(
        "dashboard.accounting.years.columns.settlementAndBalance.header",
        "Settlement & Balance"
      ),
      accessor: "is_settled",
      Cell: ({ row, value }) => {
        return value ? (
          <Trans
            i18nKey="dashboard.accounting.years.columns.settlementAndBalance.isSettled"
            t={t}
            ns="common"
            values={{
              id: row.values.id,
              payments: row.original.count_payments,
              count: row.original.count_files,
            }}
          >
            <a href={`/app/settlement/financial-year/${row.values.id}`}>
              View settlement (Paid: {{ payments: row.original.count_payments }}
              /{{ count: row.original.count_files }})
            </a>
            <a href={`/app/balance/financial-year/${row.values.id}`}>
              See closing balance
            </a>
          </Trans>
        ) : (
          <a href={`/app/settlement/financial-year/${row.values.id}`}>
            {t(
              "dashboard.accounting.years.columns.settlementAndBalance.isNotSettled",
              "Close financial year"
            )}
          </a>
        );
      },
    },
  ];

  return (
    <Table
      columns={columnsYears}
      data={data}
      title={t("dashboard.accounting.years.title", "Fiscal years")}
      addPath={hasAccessToCreate ? addPath : null}
      description={description}
      addTitle={t("dashboard.accounting.years.addButton", "New fiscal year")}
    />
  );
}
