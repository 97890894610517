import colors from "@syntbeheer/assets/colors";
import React from "react";
import { getAccountingOverview } from "@syntbeheer/api/services/accounting/index";
import CheckBox from "./../../atoms/Input/CheckBox";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { postPayment } from "@syntbeheer/api/services/accounting/index";
import DropDown from "./../../atoms/Input/DropDown";
import PaymentButton from "../../organisms/banking/PaymentButton";
import { useTranslation, Trans } from "react-i18next";
import { ExportTable } from "../../organisms/ExportTable";
import DateTimeField from "../../atoms/Input/DateTime";
import { PaymentStatus } from "../../organisms/banking/PaymentStatus";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";

export default function Accounting() {
  const [purchases, setPurchases] = React.useState([]);
  const [financialYears, setFinancialYears] = React.useState([]);
  const [showAccounting, setShowAccounting] = React.useState(false);
  const [selectedFinancialYearId, setSelectedFinancialYearId] =
    React.useState(false);
  const [selectedFinancialYear, setSelectedFinancialYear] =
    React.useState(false);
  const VME = vmesSelectors.get().VME || {};
  const { t } = useTranslation("common");

  React.useEffect(() => {
    getAccountingOverview()
      .then((response) => {
        console.log(response);
        setPurchases(response.Purchases);
        setFinancialYears(
          response.FinancialYears.sort((a, b) => (a.year > b.year ? -1 : 1))
        );
        if (response.GLAccounts?.length) {
          setShowAccounting(true);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h2 style={{ fontWeight: 700, color: colors.primary }}>
              {t("dashboard.accounting.title", "Accountancy")}
            </h2>
          </div>

          <div className="col my-auto d-flex justify-content-end">
            <DropDown
              label=""
              options={[
                {
                  id: false,
                  year: t(
                    "dashboard.accounting.fiscalYearsDropdown.allYearsOption",
                    "All fiscal years"
                  ),
                },
                ...financialYears,
              ].map((Y) => {
                Y.label = `${Y.year}`;
                return Y;
              })}
              field="id"
              value={selectedFinancialYearId}
              placeholder={t(
                "dashboard.accounting.fiscalYearsDropdown.placeholder",
                "Filter by fiscal year"
              )}
              onChange={(selected) => {
                setSelectedFinancialYearId(selected.id);
                setSelectedFinancialYear(selected);
              }}
              marginbottom={0}
              style={{ minWidth: 300 }}
            />
          </div>
        </div>

        {showAccounting ? (
          <>
            <PurchasesTable
              data={purchases.filter(
                (P) =>
                  selectedFinancialYearId === false ||
                  (new Date(P.invoice_date) >
                    new Date(selectedFinancialYear.start_date) &&
                    new Date(P.invoice_date) <
                      new Date(selectedFinancialYear.end_date))
              )}
              hasBanking={VME.has_banking}
              onChangePurchaseStatus={(id, checked) => {
                const P = purchases.find((p) => p.id === id);
                if (P) {
                  postPayment({
                    id: P.id,
                    paid_at: P.paid_at,
                    type: "Purchase",
                  });
                }

                setPurchases((purchases) =>
                  purchases.map((P) => {
                    if (P.id === id) {
                      return {
                        ...P,
                        paid_at: checked,
                      };
                    }
                    return P;
                  })
                );
              }}
            />
          </>
        ) : (
          <div>
            <Trans
              i18nKey="dashboard.accounting.hideAccounting.description"
              t={t}
              ns="common"
            >
              First of all, the general ledger accounts of the VME must be
              known. Go to{" "}
              <a href="/app/general-ledger-accounts">G/L Accounts</a>.
            </Trans>
          </div>
        )}
      </div>
    </div>
  );
}

function PurchaseStatusCell({
  hasBanking,
  row,
  value,
  onChangePurchaseStatus,
  hasAccess,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      {hasBanking && <PaymentButton id={row.values.id} type="Purchase" />}
      <div>
        <PaymentStatus
          id={row.original.id}
          type="Purchase"
          paid_at={row.original.paid_at}
          payment={row.original.payment}
        />
      </div>
      {hasAccess && !hasBanking && (
        <div>
          <CheckBox
            label=""
            checked={value}
            onChange={(event) => {
              onChangePurchaseStatus(row.values.id, event.target.checked);
            }}
            marginbottom={0}
          />
        </div>
      )}
    </div>
  );
}

function PurchasesTable({ data, hasBanking, onChangePurchaseStatus }) {
  const { t } = useTranslation("common");
  const User = usersSelectors.get().User;
  const hasAccessToCreate =
    User?.UserVME?.type !== "synt_viewer" &&
    User?.UserVME?.type !== "commissioner";
  const columnsPurchases = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.reference",
        "Reference"
      ),
      accessor: "reference",
      Cell: ({ row, value }) => {
        return hasAccessToCreate ? (
          <a href={`/app/invoice/${row.values.id}`}>{value}</a>
        ) : (
          value
        );
      },
    },
    {
      Header: t("dashboard.accounting.purchases.columns.supplier", "Supplier"),
      accessor: (row) => row.Supplier?.alias || row.Supplier?.Company?.name,
    },
    {
      Header: t("dashboard.accounting.purchases.columns.totalAmount", "Amount"),
      accessor: (row) =>
        "€" +
        row.total_amount
          .toFixed(2) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.invoiceDate",
        "Invoice date"
      ),
      accessor: "invoice_date",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.dueDate",
        "Expiration date"
      ),
      accessor: "due_date",
      Cell: ({ row, value }) => {
        return (
          <span
            style={
              !row.paid_at && new Date() > new Date(value)
                ? { color: "red" }
                : {}
            }
          >
            {new Date(value).toLocaleDateString()}
          </span>
        );
      },
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.status.header",
        "Status"
      ),
      accessor: (row) => row.paid_at != null,
      Cell: ({ row, value }) => {
        return (
          <PurchaseStatusCell
            hasBanking={hasBanking}
            onChangePurchaseStatus={onChangePurchaseStatus}
            row={row}
            value={value}
            hasAccess={hasAccessToCreate}
          />
        );
      },
    },
  ];

  const additionalColumns = [
    {
      Header: t(
        "dashboard.accounting.purchases.columns.paid_at.header",
        "Paid at"
      ),
      accessor: "paid_at",
      Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : ""),
    },
  ];

  return (
    <ExportTable
      columns={columnsPurchases}
      mapColumns={(columns) => [
        ...columns.map((column) => {
          console.log("column", column);
          if (
            column.Header ===
            t("dashboard.accounting.purchases.columns.status.header", "Status")
          ) {
            return {
              ...column,
              Cell: ({ row, value }) => {
                return (
                  <div className="d-flex justify-content-between align-items-center">
                    {value
                      ? t(
                          "dashboard.accounting.purchases.columns.status.paid",
                          "Paid"
                        )
                      : t(
                          "dashboard.accounting.purchases.columns.status.notPaid",
                          "Opened"
                        )}
                  </div>
                );
              },
            };
          }
          return column;
        }),
        ...additionalColumns,
      ]}
      data={data}
      title={t("dashboard.accounting.purchases.title", "Purchase")}
      enableSearch={true}
      enableSort={true}
      addPath={hasAccessToCreate ? "/app/invoice" : null}
      addTitle={t("dashboard.accounting.purchases.addButton", "New purchase")}
      exportFileName="purchases"
      renderDataFilterComponent={({ filters, setFilters }) => (
        <DataFilterComponent filters={filters} setFilters={setFilters} />
      )}
      dataFilter={dataFilter}
    />
  );
}

function DataFilterComponent({ filters, setFilters }) {
  const { t } = useTranslation("common");

  return (
    <div className="row">
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.startDate.label",
            "Start date"
          )}
          name="start_date"
          value={filters.start_date ?? false}
          onChange={(start_date) =>
            setFilters((prev) => ({ ...prev, start_date }))
          }
          max={filters.end_date}
          utc={true}
          futureonly={0}
        />
      </div>
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.endDate.label",
            "End date"
          )}
          name="end_date"
          value={filters.end_date}
          onChange={(end_date) => setFilters((prev) => ({ ...prev, end_date }))}
          min={filters.start_date}
          utc={true}
          futureonly={0}
        />
      </div>
    </div>
  );
}

function dataFilter(data, filters) {
  let filteredData = data;

  if (filters.start_date && filters.start_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) >= filters.start_date;
    });
  }

  if (filters.end_date && filters.end_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) <= filters.end_date;
    });
  }

  return filteredData;
}
