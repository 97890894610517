import React from "react";
import Table from "../../atoms/List/Table";
import { getAccounts } from "@syntbeheer/api/services/banking/index";
import RedirectModal from "./RedirectModal";
import { useTranslation } from "react-i18next";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";

const BankSaldo = React.forwardRef((props, ref) => {
  const [Accounts, setAccounts] = React.useState([]);
  const [Institutions, setInstitutions] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);
  const [redirect, setRedirect] = React.useState("");
  const { t } = useTranslation("common");
  const User = usersSelectors.get().User;

  const updateBanking = React.useCallback(() => {
    setAccounts([]);
    setErrorText(false);
    getAccounts().then((response) => {
      console.log({ text: "updateBanking", response });
      if (response.success) {
        // banking successful
        setAccounts(response.Accounts);
        setInstitutions(response.Institutions);
      }
      // Opgepast: user needs to have valid permissions to start banking
      if (User.UserVME?.type === "synt_authoriser" || User.is_admin) {
        if (response.redirect) {
          // redirect: auth or payment
          setShowModal(true);
          setRedirect(response.redirect);
          //window.location.href = response.redirect;
        } else if (response.error) {
          setErrorText(
            t(
              "dashboard.overview.bankConnection.error",
              "Bank connection error"
            ) +
              ": " +
              JSON.stringify(response.error)
          );
        }
      }
    });
  }, [User.UserVME?.type, User.is_admin, t]);

  React.useImperativeHandle(ref, () => ({
    updateBanking() {
      updateBanking();
    },
  }));

  React.useEffect(() => {
    //INFO: popup / auto fetching
    updateBanking();
  }, [updateBanking]);

  const columnsBankAccounts = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.overview.balance.type", "Type"),
      Cell: ({ row, value }) => {
        let Bank = Institutions.find(
          (I) =>
            I.id === row.original.relationships.financialInstitution.data.id
        );
        if (Bank) {
          return (
            <>
              {Bank.attributes.logoUrl && (
                <img
                  src={Bank.attributes.logoUrl}
                  alt={Bank.attributes.name}
                  style={{ "max-width": "50px" }}
                />
              )}
              {`${Bank.attributes.name} ${
                row.original.attributes.product
                  ? "(" + row.original.attributes.product + ")"
                  : ""
              }`}
            </>
          );
        } else {
          return row.original.attributes.product;
        }
      },
    },
    {
      Header: t("dashboard.overview.balance.accountNumber", "Account number"),
      Cell: ({ row, value }) => (
        <div style={{ "min-width": "200px" }}>
          {row.original.attributes.reference
            .replace(/[^\dA-Z]/g, "")
            .replace(/(.{4})/g, "$1 ")
            .trim()}
        </div>
      ),
    },
    {
      Header: t("dashboard.overview.balance.available", "Available"),
      Cell: ({ row, value }) => (
        <span
          style={
            !row.currentBalance < 0 ? { color: "red" } : { color: "green" }
          }
        >
          {row.original.attributes.currency +
            " " +
            row.original.attributes.currentBalance}
        </span>
      ),
    },
  ];

  return (
    <>
      {errorText && (
        <div className="alert alert-danger mt-5" role="alert">
          {errorText}
        </div>
      )}
      <RedirectModal
        show={showModal}
        redirect={() => {
          window.location.href = redirect;
        }}
        toggle={() => {
          setShowModal(!showModal);
        }}
      />
      <Table
        columns={columnsBankAccounts}
        data={Accounts}
        title={t("dashboard.overview.balance.title", "Balance of accounts")}
      />
    </>
  );
});

export default BankSaldo;
