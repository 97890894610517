import { useTranslation } from "react-i18next";
import colors from "@syntbeheer/assets/colors";

export function ProvisionPaymentStatus({ amount, paidAmount }) {
  const { t } = useTranslation("common");

  const status =
    paidAmount === 0 ? (
      <span style={{ color: colors.danger }}>
        {t("dashboard.provisionForm.transactions.statuses.notPaid", "Not paid")}
      </span>
    ) : paidAmount < amount ? (
      <span style={{ color: colors.warning }}>
        {t(
          "dashboard.provisionForm.transactions.statuses.partial",
          "Partially paid"
        )}
      </span>
    ) : paidAmount === amount ? (
      <span style={{ color: colors.success }}>
        {t("dashboard.provisionForm.transactions.statuses.paid", "Paid")}
      </span>
    ) : (
      <span style={{ color: colors.warning }}>
        {t(
          "dashboard.provisionForm.transactions.statuses.overdraft",
          "Overpaid"
        )}
      </span>
    );

  return status;
}

export function getProvisionPaymentStatus(provision) {
  const total = provision.amount ?? 0;
  const paid = (provision.payment ?? [])
    .map((p) => Number(p.amount))
    .filter((a) => !Number.isNaN(a))
    .reduce((s, a) => s + a, 0);

  return {
    total,
    paid,
    status:
      total === paid
        ? "fully_paid"
        : paid === 0
        ? "unpaid"
        : paid < total
        ? "partially_paid"
        : "overdraft",
  };
}

export function isProvisionPaid(provision) {
  const { status } = getProvisionPaymentStatus(provision);

  return status === "fully_paid" || status === "overdraft";
}
