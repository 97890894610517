/* eslint-disable jsx-a11y/heading-has-content */
import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "./../../atoms/List/Table";
import { getDefinitions } from "@syntbeheer/api/services/definitions/index";
import { useTranslation } from "react-i18next";
import RemindersTable from "../../organisms/RemindersTable";

function DefaultReminders({}) {
  const { t } = useTranslation("common");
  return (
    <RemindersTable
      title={t(
        "dashboard.definitions.reminders.tableTitle",
        "Default Reminders"
      )}
      defaultReminders
    />
  );
}

function ReportTypes({ data }) {
  const columnsReport = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "name",
      Cell: ({ row, value }) => {
        return <a href={`/app/reporttypes/${row.values.id}`}>{value}</a>;
      },
    },
    {
      Header: "",
      accessor: "category",
    },
  ];

  const { t } = useTranslation("common");

  return (
    <Table
      columns={columnsReport}
      data={data}
      title={t("dashboard.definitions.reportTypes.title", "Notification types")}
      description={t(
        "dashboard.definitions.reportTypes.description",
        "All notification types are linked to suppliers and are therefore necessary for both suppliers and new notifications."
      )}
      enableSearch={false}
      enableSort={false}
      addPath="/app/reporttypes"
    />
  );
}

function DocumentTypes({ data }) {
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "name",
      Cell: ({ row, value }) => {
        return <a href={`/app/documenttypes/${row.values.id}`}>{value}</a>;
      },
    },
    {
      Header: "",
      accessor: "category",
    },
  ];

  const { t } = useTranslation("common");

  return (
    <Table
      columns={columns}
      data={data}
      title={t("dashboard.definitions.documentTypes.title", "Document types")}
      description={t(
        "dashboard.definitions.documentTypes.description",
        "Documents divided into categories. Startup: all necessary documents when starting a new VME."
      )}
      enableSearch={false}
      enableSort={false}
      addPath="/app/documenttypes"
    />
  );
}

function MeetingItems({ data }) {
  const columnsMeetingsItems = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "name",
      Cell: ({ row, value }) => {
        return <a href={`/app/fixedmeetingitem/${row.values.id}`}>{value}</a>;
      },
    },
  ];

  const { t } = useTranslation("common");

  return (
    <Table
      columns={columnsMeetingsItems}
      data={data}
      title={t("dashboard.definitions.meetingItems.title", "Agenda items")}
      description={t(
        "dashboard.definitions.meetingItems.description",
        "Fixed agenda items that are added at every general meeting."
      )}
      enableSearch={false}
      enableSort={false}
      draggable
      addPath="/app/fixedmeetingitem"
    />
  );
}

function Journals({ data }) {
  const { t } = useTranslation("common");

  const columnsJournals = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.definitions.journals.columns.code", "Code"),
      accessor: (row) => row.code,
      Cell: ({ row, value }) => {
        return <a href={`/app/journal/${row.values.id}`}>{value}</a>;
      },
    },
    {
      Header: t("dashboard.definitions.journals.columns.name", "Name"),
      accessor: (row) => row.name,
    },
  ];

  return (
    <Table
      columns={columnsJournals}
      data={data}
      title={t("dashboard.definitions.journals.title", "Journals")}
      addPath="/app/journal"
    />
  );
}

function GeneralDefinitions({ data }) {
  const { t } = useTranslation("common");

  const columnsGLDefs = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t(
        "dashboard.definitions.generalDefinitions.columns.code",
        "Code"
      ),
      accessor: (row) => row.code,
      Cell: ({ row, value }) => {
        return (
          <a href={`/app/general-ledger-definition/${row.values.id}`}>
            {value}
          </a>
        );
      },
    },
    {
      Header: t(
        "dashboard.definitions.generalDefinitions.columns.name",
        "Name"
      ),
      accessor: (row) => row.name,
    },
  ];

  return (
    <Table
      columns={columnsGLDefs}
      data={data}
      enableSearch={true}
      enableSort={true}
      title={t(
        "dashboard.definitions.generalDefinitions.title",
        "General ledger definitions"
      )}
      addPath="/app/general-ledger-definition"
    />
  );
}

function PaymentConditions({ data }) {
  const { t } = useTranslation("common");

  const columnsPaymentConditions = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.definitions.paymentConditions.columns.name", "Name"),
      accessor: (row) => row.name,
      Cell: ({ row, value }) => {
        return <a href={`/app/payment-condition/${row.values.id}`}>{value}</a>;
      },
    },
  ];
  return (
    <Table
      columns={columnsPaymentConditions}
      data={data}
      title={t(
        "dashboard.definitions.paymentConditions.title",
        "Payment terms"
      )}
      addPath="/app/payment-condition"
    />
  );
}

export default class Definitions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DocumentTypes: [],
      ReportTypes: [],
      FixedMeetingItems: [],
      Journals: [],
      GeneralLedgerDefinitions: [],
      PaymentConditions: [],
    };
  }
  componentDidMount() {
    getDefinitions().then((response) => {
      if (response.success) {
        this.setState({
          DocumentTypes: response.DocumentTypes,
          ReportTypes: response.ReportTypes,
          FixedMeetingItems: response.FixedMeetingItems,
          Journals: response.Journals,
          GeneralLedgerDefinitions: response.GeneralLedgerDefinitions,
          PaymentConditions: response.PaymentConditions,
        });
      }
    });
  }
  render() {
    const { state } = this;
    return (
      <div className="row">
        <div className="col">
          <h2 style={{ fontWeight: 700, color: colors.primary }}></h2>

          {/*JSON.stringify(state) */}

          {/* <Table
            columns={columns}
            data={startup}
            title="Opstart"
            description="Alle noodzakelijke documenten bij opstart van een nieuwe VME."
            enableSearch={false}
            enableSort={false}
            addPath="/app/startup"
          /> */}

          <ReportTypes data={state.ReportTypes} />
          <DefaultReminders />
          <DocumentTypes data={state.DocumentTypes} />
          <MeetingItems data={state.FixedMeetingItems} />
          <Journals data={state.Journals} />
          <GeneralDefinitions data={state.GeneralLedgerDefinitions} />
          <PaymentConditions data={state.PaymentConditions} />

          {/* 
          <Table
            columns={columns}
            data={agendaItems}
            title="Algemene agendapunten"
            description="Dit vergt waarschijnlijk extra codering en moet bekeken worden hoe dit wordt geïmplementeerd."
            enableSearch={false}
            enableSort={false}
            addPath="/app/agendaitems"
          /> */}
        </div>
      </div>
    );
  }
}
