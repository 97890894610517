import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "./../../atoms/List/Table";

import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import {
  getDevices,
  deleteDevice,
  revoke,
} from "@syntbeheer/api/services/users/index";
import DeleteIcon from "@material-ui/icons/Delete";
import RequestForm from "./../../organisms/RequestForm";
import { useTranslation } from "react-i18next";
import { postLocale } from "@syntbeheer/api/services/users/index";
import LanguagePicker from "../../atoms/Input/LanguagePicker";

export default function Profile() {
  const User = usersSelectors.get().User;
  const VME = vmesSelectors.get().VME || {};
  const [Devices, setDevices] = React.useState([]);
  const { t } = useTranslation("common");

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    getDevices()
      .then((response) => {
        console.log(response);
        if (response.success) {
          setDevices(response.Devices);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const onSessionDelete = (id) => {
    setDevices(Devices.filter((D) => D.id !== id));
    deleteDevice(id)
      .then((response) => {
        console.log(response);
        if (response.logout) {
          revoke().then((response) => {
            if (response.success) {
              window.location.href = "/";
            }
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const changeLang = (value) => {
    postLocale({ language: value });
  };

  return (
    <div className="row">
      <div className="col">
        <h2 className="mb-5" style={{ fontWeight: 700, color: colors.primary }}>
          {t("dashboard.profile.title", "Profile")}
        </h2>
        {/*         
        <p>
          {t("dashboard.profile.contactInfo", "If you have any problems or questions, please contact")}{" "}
          <a href="mailto:beheer@synt.be" target="_blank" rel="noreferrer">
            beheer@synt.be
          </a>
          .
        </p> 
        */}
        <RequestForm
          message=""
          type="general"
          title={t(
            "dashboard.profile.requestForm.title",
            "Give comment/suggestion"
          )}
          description={t(
            "dashboard.profile.requestForm.description",
            "Have a problem or suggestion? Let us know."
          )}
        />
        <div>
          <Table
            title={t(
              "dashboard.profile.personalInfo.title",
              "Your personal information"
            )}
            columns={[
              {
                Header: "",
                accessor: "title",
              },
              {
                Header: "",
                accessor: "value",
              },
            ]}
            data={[
              {
                title: t(
                  "dashboard.profile.personalInfo.data.firstName",
                  "First name"
                ),
                value: User.first_name,
              },
              {
                title: t(
                  "dashboard.profile.personalInfo.data.lastName",
                  "Last name"
                ),
                value: User.last_name,
              },
              {
                title: t("dashboard.profile.personalInfo.data.email", "Email"),
                value: User.email,
              },
              {
                title: t("dashboard.profile.personalInfo.data.phone", "Phone"),
                value: User.phone,
              },
              {
                title: t(
                  "dashboard.profile.personalInfo.data.language",
                  "Language"
                ),
                value: (
                  <LanguagePicker menuPlacement="top" onChange={changeLang} />
                ),
              },
            ]}
          />
          <Table
            title={t("dashboard.profile.vme.title", "Your VME")}
            columns={[
              {
                Header: "",
                accessor: "title",
              },
              {
                Header: "",
                accessor: "value",
              },
            ]}
            data={[
              {
                title: t("dashboard.profile.vme.data.alias", "Alias"),
                value: VME.alias,
              },
              {
                title: t(
                  "dashboard.profile.vme.data.vatNumber",
                  "Company number"
                ),
                value: VME.vat_number,
              },
              {
                title: t(
                  "dashboard.profile.vme.data.officialName",
                  "Official name"
                ),
                value: VME.name,
              },
              {
                title: t("dashboard.profile.vme.data.address", "Address"),
                value: VME.address,
              },
              {
                title: t("dashboard.profile.vme.data.account", "Account"),
                value: VME.current_account,
              },
              {
                title: t(
                  "dashboard.profile.vme.data.savingsAccount",
                  "Savings account"
                ),
                value: VME.savings_account,
              },
              {
                title: t("dashboard.profile.vme.data.insurer", "Insurer"),
                value: VME.insurance_company,
              },
              {
                title: t(
                  "dashboard.profile.vme.data.policyNumber",
                  "Policy number"
                ),
                value: VME.insurance_number,
              },
              {
                title: t(
                  "dashboard.profile.vme.data.totalQuotas",
                  "Total Quotas"
                ),
                value: VME.total_shares,
              },
              {
                title: t(
                  "dashboard.profile.vme.data.numberOfUsers",
                  "Number of users"
                ),
                value: (VME.Users || []).length,
              },
            ]}
          />
          <Table
            title={t("dashboard.profile.sessions.title", "Your sessions")}
            columns={[
              {
                Header: "Id",
                accessor: "id",
              },
              {
                Header: t(
                  "dashboard.profile.sessions.columns.deviceName",
                  "Device name"
                ),
                accessor: "device_name",
              },
              {
                Header: t(
                  "dashboard.profile.sessions.columns.deviceUserAgent",
                  "Browser User Agent"
                ),
                accessor: "device_userAgent",
              },
              {
                Header: t(
                  "dashboard.profile.sessions.columns.ipAddress",
                  "Ip Address"
                ),
                accessor: "ip_address",
              },
              {
                Header: t(
                  "dashboard.profile.sessions.columns.loggedInFrom",
                  "Logged in from"
                ),
                accessor: (row) =>
                  new Date(row.createdAt).toLocaleDateString() +
                  ", " +
                  new Date(row.createdAt).toLocaleString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
              },
              {
                Header: () => null, // No header
                id: "login",
                Cell: ({ row }) => (
                  <div style={{ textAlign: "right" }}>
                    <button
                      className="btn p-0 ml-auto"
                      onClick={() => onSessionDelete(row.values.id)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                ),
              },
            ]}
            data={Devices}
          />
        </div>
      </div>
    </div>
  );
}
