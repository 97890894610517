import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "./../../atoms/List/Table";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import {
  getNotifications,
  postReadNotification,
} from "@syntbeheer/api/services/notifications/index";
import IncidentIcon from "@material-ui/icons/Opacity";
import PersonIcon from "@material-ui/icons/AccountCircleRounded";
import AgendaIcon from "@material-ui/icons/ViewAgenda";
import CreateFolderIcon from "@material-ui/icons/CreateNewFolderRounded";
import ReminderIcon from "@material-ui/icons/NotificationsActive";
// var QRCode = require("qrcode.react");
import BankSaldo from "./../../organisms/banking/Saldo";
import { withTranslation } from "react-i18next";

const columnsNotifications = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "",
    accessor: "title",
    Cell: ({ row, value }) => {
      return (
        <div
          className="btnify"
          onClick={() => {
            postReadNotification({ id: row.original.id });
            window.location.href = row.original.path;
          }}
        >
          <b>{value}</b>
          <br />
          {row.original.description}
        </div>
      );
    },
  },
];

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Notifications: [],
    };
  }
  componentDidMount() {
    getNotifications().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({
          Notifications: response.Notifications.sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : 1
          ),
        });
      }
    });
  }

  render() {
    const User = usersSelectors.get().User;
    const { t } = this.props;

    const hasAccessToCreate =
      User?.UserVME?.type !== "synt_viewer" &&
      User?.UserVME?.type !== "commissioner";

    return (
      <div className="row">
        <div className="col">
          <h2 style={{ fontWeight: 700, color: colors.primary }}>
            {t("dashboard.overview.welcome", "Welcome")}&nbsp;
            {User.first_name}
          </h2>
          <div className="row">
            <div className="col">
              {hasAccessToCreate ? (
                <Table
                  columns={[
                    {
                      Header: "",
                      accessor: "title",
                    },
                  ]}
                  data={[
                    {
                      title: (
                        <a href="/app/incident">
                          <IncidentIcon /> &nbsp;
                          {t(
                            "dashboard.overview.newNotifications",
                            "New notification"
                          )}
                        </a>
                      ),
                    },
                    {
                      title: (
                        <a href="/app/document">
                          <CreateFolderIcon /> &nbsp;
                          {t("dashboard.overview.newDocuments", "New document")}
                        </a>
                      ),
                    },
                    {
                      title: (
                        <a href="/app/agenda">
                          <AgendaIcon /> &nbsp;
                          {t("dashboard.overview.newEvent", "New event")}
                        </a>
                      ),
                    },
                    {
                      title: (
                        <a href="/app/User">
                          <PersonIcon /> &nbsp;
                          {t("dashboard.overview.newUser", "New user")}
                        </a>
                      ),
                    },
                    {
                      title: (
                        <a href="/app/reminders">
                          <ReminderIcon /> &nbsp;
                          {t("dashboard.overview.newReminder", "New reminder")}
                        </a>
                      ),
                    },
                  ]}
                  title={t("dashboard.overview.shortcuts", "Shortcuts")}
                />
              ) : null}
              <BankSaldo />
            </div>
            <div className="col">
              <Table
                columns={columnsNotifications.map((N) => ({
                  ...N,
                  label: (
                    <>
                      <b>{N.title}</b>
                      <br />
                      {N.description}
                    </>
                  ),
                }))}
                data={this.state.Notifications.filter(
                  (N) => !N.seen_at || (N.seen_at && this.state.is_more)
                )}
                title={t(
                  "dashboard.overview.communication.title",
                  "Communication"
                )}
              />
              <div
                className="btn-link text-center"
                onClick={() => this.setState({ is_more: !this.state.is_more })}
              >
                {this.state.is_more
                  ? t(
                      "dashboard.overview.communication.showLess",
                      "▴ Show Less"
                    )
                  : t("dashboard.overview.communication.readMore", "Read More")}
              </div>
            </div>
          </div>

          {/* <p></p>
          <p>
            Gebruik de Synt app om als mede-eigenaar meldingen toe te voegen en
            andere. <br />
            Klik op volgende link:{" "}
            <a href="http://syntbeheer.be/app">syntbeheer.be/app</a> of richt je
            camera met je smartphone naar volgende QR-code:
          </p>
          <QRCode value="https://syntbeheer.be/app" /> */}
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Overview);
