import React from "react";
import Table from "./../../atoms/List/Table";
import { useEffect, useState, useCallback } from "react";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { getLots } from "@syntbeheer/api/services/lots/index";
import { getUsers } from "@syntbeheer/api/services/users/index";
import { getDistributionKeys } from "@syntbeheer/api/services/distributionkeys/index";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import PersonIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import { Trans, useTranslation } from "react-i18next";

function Data() {
  const { t } = useTranslation("common");
  const [Lots, setLots] = useState([]);
  const [Users, setUsers] = useState([]);
  const [DistributionKeys, setDistributionKeys] = useState([]);
  const VME = vmesSelectors.get().VME || {};
  const User = usersSelectors.get().User;

  const columnsUsers = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.data.users.columns.email", "Email"),
      accessor: "email",
      Cell: ({ row, value }) => (
        <span
          style={
            row.original.UserVME?.is_disabled
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {!User.is_admin &&
          User.UserVME &&
          User.UserVME?.type !== "synt_authoriser" ? (
            <span>{value}</span>
          ) : (
            <a href={`/app/User/${row.values.id}`}>{value}</a>
          )}
        </span>
      ),
    },
    {
      Header: t("dashboard.data.users.columns.lastName", "Last name"),
      accessor: "last_name",
      Cell: ({ row, value }) => (
        <span
          style={
            row.original.UserVME?.is_disabled
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {value}
        </span>
      ),
    },
    {
      Header: t("dashboard.data.users.columns.firstName", "First name"),
      accessor: "first_name",
      Cell: ({ row, value }) => (
        <span
          style={
            row.original.UserVME?.is_disabled
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {value}
        </span>
      ),
    },
    {
      Header: t("dashboard.data.users.columns.type", "Type"),
      accessor: (row) => row.role,
      Cell: ({ row, value }) => (
        <span
          style={
            row.original.UserVME?.is_disabled
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {t(value, value)}{" "}
          {row.original.Company ? <CompanyIcon /> : <PersonIcon />}
        </span>
      ),
    },
  ];

  const columnsLots = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.data.lots.columns.name", "Name"),
      accessor: "name",
      Cell: ({ row, value }) =>
        !User.is_admin &&
        User.UserVME &&
        User.UserVME?.type !== "synt_authoriser" ? (
          <span>{value}</span>
        ) : (
          <a href={`/app/lot/${row.values.id}`}>{value}</a>
        ),
    },
    {
      Header: t("dashboard.data.lots.columns.share", "Quotas"),
      accessor: (row) =>
        row.share.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
    },
    {
      Header: t("dashboard.data.lots.columns.users", "Users"),
      accessor: (row) => {
        let P = row.LotPhases.find((P) => !P.ends_at);
        if (P) {
          return P.Users.map(
            (U) =>
              U.LotPhaseUser.type === "owner" && (
                <div key={`User${U.id}`}>
                  {U.Company ? (
                    <span>
                      {`${U.Company?.name} (${U.full_name})`} <CompanyIcon />
                    </span>
                  ) : (
                    <span>
                      {U.full_name} <PersonIcon />
                    </span>
                  )}
                </div>
              )
          );
        }
      },
    },
  ];

  const columnsDistributionKeys = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("dashboard.data.distributionKeys.columns.name", "Name"),
      accessor: "name",
      Cell: ({ row, value }) =>
        !User.is_admin &&
        User.UserVME &&
        User.UserVME?.type !== "synt_authoriser" ? (
          <span>{value}</span>
        ) : (
          <a href={`/app/distribution-key/${row.values.id}`}>{value}</a>
        ),
    },
    {
      Header: t("dashboard.data.distributionKeys.columns.type.header", "Type"),
      accessor: (row) =>
        row.type === "S"
          ? t("dashboard.data.distributionKeys.columns.type.quotas", "Quotas")
          : t("dashboard.data.distributionKeys.columns.type.units", "Units"),
    },
    {
      Header: t(
        "dashboard.data.distributionKeys.columns.count",
        "# Lots",
        ": "
      ),
      accessor: (row) => row.Lots.length,
    },
  ];

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    getUsers()
      .then((response) => {
        console.log(response);
        if (response.success) {
          setUsers(response.Users);
        }
      })
      .catch((e) => console.log(e));
    getLots()
      .then((response) => {
        console.log(response);
        if (response.success) {
          setLots(response.Lots);
        }
      })
      .catch((e) => console.log(e));

    getDistributionKeys()
      .then((response) => {
        console.log(response);
        if (response.success) {
          setDistributionKeys(response.DistributionKeys);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row }) => {
      let selectedLot;
      Lots.forEach((Lot) => {
        if (Lot.id === row.values.id) {
          selectedLot = Lot;
        }
      });
      return (
        <pre
          style={{
            fontSize: "10px",
          }}
          key={row.values.id}
        >
          <code>{JSON.stringify({ selectedLot }, null, 2)}</code>
        </pre>
      );
    },
    [Lots]
  );

  const allocated_shares = Lots.reduce((a, i) => a + i.share, 0);

  return (
    <div className="row">
      <div className="col">
        <Table
          columns={columnsUsers}
          data={Users}
          title={
            t("dashboard.data.users.title", "Users") +
            ` ${VME.alias || ""} (${Users.length})`
          }
          description=""
          enableSearch={false}
          enableSort={false}
          addPath={
            !User.is_admin && User.UserVME?.type !== "synt_authoriser"
              ? null
              : "/app/User"
          }
        />

        <Table
          columns={columnsLots}
          data={Lots}
          title={
            t("dashboard.data.lots.title", "Lots") +
            ` ${VME.alias || ""} (${allocated_shares
              ?.toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}/${
              VME.total_shares
                ?.toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") ?? "0"
            })`
          }
          enableSearch={false}
          enableSort={false}
          addPath={
            !User.is_admin && User.UserVME?.type !== "synt_authoriser"
              ? null
              : VME.is_frozen
              ? false
              : "/app/lot"
          }
          renderRowSubComponent={renderRowSubComponent}
        />

        <Table
          columns={columnsDistributionKeys}
          data={DistributionKeys}
          title={
            t("dashboard.data.distributionKeys.title", "Distribution keys") +
            ` ${VME.alias || ""}`
          }
          description={
            (User.is_admin ||
              !(
                User.UserVME?.type === "regular_user" ||
                User.UserVME?.type === "synt_viewer" ||
                User.UserVME?.type === "commissioner"
              )) && (
              <Trans
                i18nKey="dashboard.data.distributionKeys.description.forAdmin"
                t={t}
                ns="common"
              >
                <p className="mb-0 mt-1">
                  Go to the{" "}
                  <a href="/app/general-ledger-accounts">
                    general ledger accounts
                  </a>
                  .
                </p>
                <p>
                  {allocated_shares / VME.total_shares !== 1
                    ? `${t(
                        "dashboard.data.distributionKeys.description.attention",
                        "Attention: you must first allocate all lots before you can create allocation keys."
                      )} (${allocated_shares} / ${VME.total_shares})`
                    : ""}
                </p>
              </Trans>
            )
          }
          enableSearch={false}
          enableSort={false}
          addPath={
            (!User.is_admin && User.UserVME?.type !== "synt_authoriser") ||
            allocated_shares / VME.total_shares !== 1
              ? null
              : VME.is_frozen
              ? false
              : "/app/distribution-key"
          }
          renderRowSubComponent={renderRowSubComponent}
        />
      </div>
    </div>
  );
}

export default Data;
